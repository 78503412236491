<template>
  <!-- side popup modal -->
  <form
    method="post"
    @submit.prevent="submitHandler()"
    enctype="multipart/form-data"
  >
    <div class="form-field-group">
      <h3 class="form-title">
        <template v-if="verification.Id">Edit Verification</template>
        <template v-else>Add Verification</template>
      </h3>
      <div class="row grid-1">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="profile">Profile<span>*</span></label>
          <div class="form-field">
            <template
              v-if="
                verification.uproster_profile &&
                verification.uproster_profile.deleted_at
              "
            >
              <input
                class="form-control"
                type="text"
                :value="verification.uproster_profile.ProfileName"
                :disabled="true"
                readonly
              />
            </template>
            <template v-else>
              <Select2
                :id="`verification-profile`"
                v-model.trim="verification.uprosterProfileId"
                class="select2-box"
                :options="profiles.uprosterProfiles"
                :placeholder="`Select Profile`"
                :resetOptions="false"
                :allowClear="true"
              />
            </template>
            <span
              v-if="
                verification.submitted &&
                v$.verification.uprosterProfileId.required.$invalid
              "
              class="error"
              >* required</span
            >
          </div>
        </div>
      </div>
      <div class="row grid-1">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="person">Person Responsible<span>*</span></label>
          <div class="form-field">
            <template
              v-if="
                verification.b_d_team_profile &&
                verification.b_d_team_profile.deleted_at
              "
            >
              <input
                class="form-control"
                type="text"
                :value="verification.b_d_team_profile.Name"
                :disabled="true"
                readonly
              />
            </template>
            <template v-else>
              <Select2
                :id="`verification-responsible-person`"
                v-model.trim="verification.responsiblePersonId"
                class="select2-box"
                :options="profiles.BDTeamProfiles"
                :placeholder="`Select Person Responsible`"
                :resetOptions="false"
                :allowClear="true"
              />
            </template>
            <span
              v-if="
                verification.submitted &&
                v$.verification.responsiblePersonId.required.$invalid
              "
              class="error"
              >* required</span
            >
          </div>
        </div>
      </div>
      <div class="row grid-1">
        <div class="col form-group d-flex upload-wrap">
          <label class="form-label" for="type">Submit Document</label>
          <div>
            <div
              class="upload-file-field"
              :class="{
                disabled:
                  totalDocuments >= 5 ||
                  ['verified', 'failure'].includes(oldStatus),
              }"
            >
              <input
                class="form-control"
                type="file"
                ref="documents"
                multiple="multiple"
                :disabled="
                  totalDocuments >= 5 ||
                  ['verified', 'failure'].includes(oldStatus)
                "
                @change="uploadDocuments()"
                :accept="`.${allowFileTypes.join(', .')}`"
              />
              <label class="placeholder">Upload Files</label>
              <span class="field-icon"
                ><font-awesome-icon :icon="['fa', 'cloud-upload-alt']" />
                UPLOAD</span
              >
              <span
                v-if="v$.verification.documents.maxLength.$invalid"
                class="error"
                >Maximum 5 documents allowed.</span
              >
            </div>
            <div class="uploaded-doc">
              <template
                v-for="(document, index) in verification.uploadedDocuments"
                :key="index"
              >
                <div class="uploaded-docoments">
                  <a
                    class="underline-link"
                    :href="`${uploadPath + document.Documents}`"
                    target="_blank"
                    >{{ document.DocumentName }}</a
                  >
                  <a
                    v-if="!['verified', 'failure'].includes(oldStatus)"
                    href="javascript:void(0);"
                    @click.prevent="removeUploadedDocument(document.Id)"
                    ><span class="lnr lnr-cross-circle text-danger"></span
                  ></a>
                </div>
              </template>
              <template
                v-for="(document, index) in verification.documents"
                :key="index"
              >
                <div class="uploaded-docoments">
                  <a
                    class="underline-link"
                    :href="`${document.path}`"
                    target="_blank"
                    >{{ document.name }}</a
                  >
                  <a
                    v-if="!['verified', 'failure'].includes(oldStatus)"
                    href="javascript:void(0);"
                    @click.prevent="removeDocument(index)"
                    ><span class="lnr lnr-cross-circle text-danger"></span
                  ></a>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="row grid-1">
        <div class="col form-group d-flex align-items-center radio-fields">
          <label class="form-label" for="type">Status</label>
          <div class="radio-options">
            <ul class="d-flex align-items-center inline-radio">
              <li>
                <input
                  type="radio"
                  id="progress"
                  name="radio-group"
                  v-model="verification.status"
                  value="inProgress"
                  :disabled="['verified', 'failure'].includes(oldStatus)"
                />
                <label for="progress">In-Progress</label>
              </li>
              <li>
                <input
                  type="radio"
                  id="verified"
                  name="radio-group"
                  v-model="verification.status"
                  value="verified"
                  :disabled="['failure'].includes(oldStatus)"
                />
                <label for="verified">Verified</label>
              </li>
              <li>
                <input
                  type="radio"
                  id="failure"
                  name="radio-group"
                  v-model="verification.status"
                  value="failure"
                  :disabled="['verified'].includes(oldStatus)"
                />
                <label for="failure">Failure</label>
              </li>
            </ul>
            <span
              v-if="
                verification.submitted &&
                v$.verification.status.required.$invalid
              "
              class="error"
              >* required</span
            >
          </div>
        </div>
      </div>
      <div class="row grid-1" v-if="verification.status === 'failure'">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="proLevel">Reason</label>
          <div class="form-field">
            <textarea
              v-model.trim="verification.reason"
              class="form-control"
              placeholder="Reason"
            ></textarea>
            <span
              v-if="
                verification.submitted &&
                v$.verification.reason.required.$invalid
              "
              class="error"
              >* required</span
            >
            <span
              v-if="
                verification.submitted &&
                v$.verification.reason.maxLength.$invalid
              "
              class="error"
              >Max {{ v$.verification.reason.maxLength.$params.max }} characters
              allowed.</span
            >
          </div>
        </div>
      </div>
      <div class="form-btns">
        <div class="d-flex justify-content-end">
          <button
            class="secondary-btn"
            title="Cancel"
            type="button"
            @click.prevent="$emit('close')"
          >
            CANCEL
          </button>
          <button
            class="primary-btn"
            title="Update"
            type="submit"
            @click.prevent="submitHandler()"
          >
            SAVE
          </button>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
library.add(faCloudUploadAlt);
import useVuelidate from "@vuelidate/core";
import { required, requiredIf, maxLength } from "@vuelidate/validators";
import common from "../mixins/common";
import verificationDashboardService from "../services/verificationDashboard.service";
import Select2 from "./Select2.vue";

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    fields: {
      type: Object,
    },
  },
  components: {
    FontAwesomeIcon,
    Select2,
  },
  data: () => ({
    uploadPath: process.env.VUE_APP_UPLOAD_BASE_PATH,
    allowFileTypes: [
      "jpg",
      "jpeg",
      "png",
      "pdf",
      "xls",
      "xlsx",
      "docx",
      "csv",
      "txt",
    ],
    profiles: {
      uprosterProfiles: [],
      BDTeamProfiles: [],
    },
    verification: {
      Id: 0,
      submitted: false,
      uprosterProfileId: "",
      responsiblePersonId: "",
      documents: [],
      uploadedDocuments: [],
      reason: "",
      status: "inProgress",
      uproster_profile: {
        ProfileName: "",
        deleted_at: null,
      },
      b_d_team_profile: {
        Name: "",
        deleted_at: null,
      },
    },
    oldStatus: "",
    removedUploadedDocumentIds: [],
  }),
  computed: {
    totalDocuments() {
      return (
        this.verification.documents.length +
        this.verification.uploadedDocuments.length
      );
    },
  },
  mixins: [common],
  validations() {
    return {
      verification: {
        uprosterProfileId: {
          required,
        },
        responsiblePersonId: {
          required,
        },
        status: {
          required,
        },
        reason: {
          required: requiredIf(function () {
            if (this.verification.status === "failure") {
              return true;
            } else {
              this.verification.reason = "";
            }
            return false;
          }),
          maxLength: maxLength(150),
        },
        documents: {
          maxLength: function (value) {
            const max = 5;
            let totalFileUploaded =
              this.verification.uploadedDocuments.length + value.length;
            if (totalFileUploaded > max) {
              return false;
            }

            return true;
          },
        },
      },
    };
  },
  async created() {
    await this.getProfiles();
    if (this.fields) {
      this.verification = this.fields;
      this.oldStatus = this.fields.status;
    }
  },
  methods: {
    async getProfiles() {
      let vm = this;
      try {
        vm.$store.commit("loadingStatus", true);
        const response = await verificationDashboardService.getProfiles();
        vm.profiles.uprosterProfiles = response.uprosterProfiles;
        vm.profiles.BDTeamProfiles = response.BDTeamProfiles;
        vm.$store.commit("loadingStatus", false);
      } catch (error) {
        const message = vm.errorMessage(error);
        vm.toastMessage(message, "error");
        vm.$store.commit("loadingStatus", false);
      }
    },
    async submitHandler() {
      let vm = this;

      vm.verification.submitted = true;
      vm.v$.$touch();

      const isValidate = await vm.v$.$validate();

      if (!isValidate) return;

      let formData = new FormData();

      formData.append("uprosterProfileId", vm.verification.uprosterProfileId);
      formData.append(
        "responsiblePersonId",
        vm.verification.responsiblePersonId
      );
      formData.append("status", vm.verification.status);
      formData.append("comment", vm.verification.reason);

      for (let i = 0; i < vm.removedUploadedDocumentIds.length; i++) {
        formData.append(
          "removedDocumentIds[" + i + "]",
          vm.removedUploadedDocumentIds[i]
        );
      }

      for (let i = 0; i < vm.verification.documents.length; i++) {
        let file = vm.verification.documents[i];
        formData.append("documents[" + i + "]", file);
      }

      if (vm.verification.Id) {
        try {
          vm.$store.commit("loadingStatus", true);
          const response = await verificationDashboardService.update(
            formData,
            vm.verification.Id
          );
          vm.toastMessage(response.message, "success");
          vm.$emit("close");
          vm.$emit("created");
          vm.$store.commit("loadingStatus", false);
        } catch (error) {
          const message = vm.errorMessage(error);
          vm.toastMessage(message, "error");
          vm.$store.commit("loadingStatus", false);
        }
      } else {
        try {
          vm.$store.commit("loadingStatus", true);
          const response = await verificationDashboardService.create(formData);
          vm.toastMessage(response.message, "success");
          vm.$emit("close");
          vm.$emit("created");
          vm.$store.commit("loadingStatus", false);
        } catch (error) {
          const message = vm.errorMessage(error);
          vm.toastMessage(message, "error");
          vm.$store.commit("loadingStatus", false);
        }
      }
    },
    uploadDocuments() {
      let vm = this;
      const files = vm.$refs.documents.files;
      const finalFile = Array.from(files);

      for (let i = 0; i < finalFile.length; i++) {
        let ext = finalFile[i].name.split(".").pop();
        if (!vm.allowFileTypes.includes(ext)) {
          vm.toastMessage(
            `The documents must be a file of type: ${vm.allowFileTypes.join(
              ", "
            )}`,
            "error"
          );
          finalFile.splice(i, 1);
        }

        finalFile[i].path = URL.createObjectURL(finalFile[i]);

        if (finalFile[i].size > 102400 * 7) {
          vm.toastMessage("Document should be less or equal to 7MB.", "error");
          finalFile.splice(i, 1);
        }
      }

      if (finalFile.length > 0) {
        Array.prototype.push.apply(vm.verification.documents, finalFile);
      }
    },
    removeDocument(index) {
      let vm = this;
      vm.verification.documents.splice(index, 1);
    },
    removeUploadedDocument(Id) {
      let vm = this;

      let index = vm.verification.uploadedDocuments.findIndex(
        (document) => document.Id === Id
      );

      vm.verification.uploadedDocuments.splice(index, 1);
      vm.removedUploadedDocumentIds.push(Id);
    },
  },
};
</script>
