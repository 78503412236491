<template>
  <div
    class="status-table-wrapper custom-table no-arrows d-flex align-items-start"
  >
    <table class="verified-profiles">
      <thead>
        <tr>
          <th>Verified Profiles</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="profile in data.verifiedProfiles" :key="profile.Id">
          <td>{{ profile.uproster_profile.ProfileName }}</td>
        </tr>
      </tbody>
    </table>
    <table class="failure-profiles">
      <thead>
        <tr>
          <th>Failed Profiles</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="profile in data.verificationFailureProfiles"
          :key="profile.Id"
        >
          <td>
            {{ profile.uproster_profile.ProfileName }}
            <font-awesome-icon
              :icon="['fas', 'info-circle']"
              class="text-danger"
              v-tippy="`${profile.FailureComment}`"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

library.add(faInfoCircle);

export default {
  components: {
    FontAwesomeIcon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
