<template>
  <div class="profile-data-wrapper" v-show="!loading">
    <template
      v-if="
        userPermissions &&
        userPermissions.profile_verification &&
        userPermissions.profile_verification.length > 0 &&
        userPermissions.profile_verification.includes('View')
      "
    >
      <div class="profile-options">
        <div class="row align-items-center justify-content-between">
          <div class="col">
            <h2 class="section-heading">RECENT VERIFICATIONS</h2>
          </div>
          <div class="col">
            <a
              href="javascript:void(0);"
              class="secondary-btn"
              @click="openSideModal('createVerification')"
              v-if="userPermissions.profile_verification.includes('Add')"
              >Add new Record</a
            >
            <button
              class="primary-btn"
              v-if="recentVerifications.length"
              type="button"
              @click="downloadRecentVerificationCsv()"
            >
              <i class="fas fa-download"></i>Download All
            </button>
          </div>
          <!-- End div center   -->
        </div>
      </div>
      <div class="custom-table no-arrows">
        <table class="table" id="recent-verification">
          <thead>
            <tr>
              <th>Date</th>
              <th>Profile</th>
              <th>Documents</th>
              <th>Person responsible for conduction</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="data in recentVerifications" :key="data.Id">
              <td>{{ $filters.dateFormat(data.created_at, "DD/MM/YYYY") }}</td>
              <td>{{ data.uproster_profile.ProfileName }}</td>
              <td>
                <ul>
                  <li v-for="document in data.documents" :key="document.id">
                    <a
                      :href="`${uploadPath + document.Documents}`"
                      class="underline-link"
                      target="_blank"
                      >{{ document.DocumentName }}</a
                    >
                  </li>
                </ul>
              </td>
              <td>{{ data.b_d_team_profile.Name }}</td>
              <td>
                <template v-if="data.Status === 'failure'">Failure</template>
                <template v-if="data.Status === 'verified'">Verified</template>
                <template v-if="data.Status === 'inProgress'"
                  >In-Progress</template
                >
              </td>
              <td>
                <div class="table-action">
                  <ul class="d-flex">
                    <li
                      v-if="
                        ['verified', 'failure'].includes(data.Status) &&
                        userPermissions.profile_verification.includes('View')
                      "
                    >
                      <a
                        href="javascript:void(0);"
                        class="custom-tooltip"
                        v-tippy="`View`"
                        @click="openSideModal('viewVerification', data.Id)"
                        ><span class="lnr lnr-eye"></span
                      ></a>
                    </li>
                    <li
                      v-if="
                        !['verified', 'failure'].includes(data.Status) &&
                        userPermissions.profile_verification.includes('Update')
                      "
                    >
                      <a
                        href="javascript:void(0);"
                        class="custom-tooltip"
                        v-tippy="`Edit`"
                        @click="openSideModal('editVerification', data.Id)"
                        ><span class="lnr lnr-pencil"></span
                      ></a>
                    </li>
                    <li
                      v-if="
                        userPermissions.profile_verification.includes('Delete')
                      "
                    >
                      <a
                        href="javascript:void(0);"
                        class="custom-tooltip"
                        v-tippy="`Delete`"
                        @click.prevent="deleteRecentVerification(data.Id)"
                        ><span class="lnr lnr-trash"></span
                      ></a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="profile-options">
        <div class="row align-items-center justify-content-between">
          <div class="col">
            <h2 class="section-heading">BUSINESS TEAM STATS</h2>
          </div>
          <div class="col">
            <button
              class="primary-btn"
              v-if="verificationStatus.length"
              type="button"
              @click="downloadVerificationStatusCsv()"
            >
              <i class="fas fa-download"></i>Download All
            </button>
          </div>
          <!-- End div center   -->
        </div>
      </div>
      <div class="custom-table no-arrows">
        <table class="table" id="verification-status">
          <thead>
            <tr>
              <th>Name</th>
              <th>Role</th>
              <th>Total Profiles Handled</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="data in verificationStatus" :key="data.Id">
              <td>{{ data.Name }}</td>
              <td>{{ data.Role }}</td>
              <td>{{ data.verificationHandledCount }}</td>
              <td>
                <div class="table-action">
                  <ul class="d-flex">
                    <li>
                      <a
                        href="javascript:void(0);"
                        class="custom-tooltip"
                        @click.prevent="openSideModal('viewStats', data.Id)"
                      >
                        <span class="lnr lnr-eye" v-tippy="`View`"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <SideModal
        @close="closeSideModal"
        v-bind:contentClass="[
          'sm-side--modal',
          { 'verification-modal': sideModals.addEdit.show },
        ]"
      >
        <template v-slot:header>
          <template v-if="sideModals.newRecord.show"
            >CREATE A NEW RECORD</template
          >
          <!-- <template v-if="sideModals.addEdit.show">
                  VERIFICATION DASHBOARD 
                  (<template v-if="verification.fields">EDIT</template><template v-else>ADD</template>)
              </template> -->
          <template v-if="sideModals.viewStats.show">
            {{ viewStats.BDTeamProfileName }} Verification Status
          </template>
        </template>
        <template v-slot:body>
          <div
            class="new-record--wrap text-center"
            v-if="sideModals.newRecord.show"
          >
            <a
              href="javascript:void(0);"
              class="secondary-btn w-100"
              @click="openSideModal('createVerification')"
              >create a new record manually</a
            >
            <div class="seprater">or</div>
            <a
              href="javascript:void(0);"
              class="primary-btn w-100"
              @click.prevent="openSideModal('importVerification')"
              >import data</a
            >
          </div>
          <!-- <add-edit-verification v-if="sideModals.addEdit.show" :fields="verification.fields" @close="closeSideModal" @created="getData()"></add-edit-verification> -->
          <view-verification-status
            v-if="sideModals.viewStats.show"
            :data="viewStats.data"
          ></view-verification-status>
        </template>
      </SideModal>
      <SideModal
        @close="closeSideModal"
        v-bind:contentClass="[
          'sm-side--modal',
          { 'verification-modal': sideModals.addEdit.show },
        ]"
        v-show="
          sideModals.addEdit.show ||
          sideModals.import.show ||
          sideModals.view.show
        "
      >
        <template v-slot:header>
          <template v-if="sideModals.addEdit.show">
            VERIFICATION DASHBOARD (<template v-if="verification.fields"
              >EDIT</template
            ><template v-else>ADD</template>)
          </template>
          <template v-if="sideModals.import.show">
            Import VERIFICATION
          </template>
          <template v-if="sideModals.view.show"> View VERIFICATION </template>
        </template>
        <template v-slot:body>
          <add-edit-verification
            v-if="sideModals.addEdit.show"
            :fields="verification.fields"
            @close="closeSideModal"
            @created="getData()"
          ></add-edit-verification>
          <view-verification
            v-if="sideModals.view.show"
            :fields="verification.fields"
            @close="closeSideModal"
            @created="getData()"
          ></view-verification>
          <ImportVerification
            v-if="sideModals.import.show"
            @close="closeSideModal"
            @imported="getData()"
          ></ImportVerification>
        </template>
      </SideModal>
    </template>
    <template
      v-if="
        userPermissions &&
        userPermissions.profile_verification &&
        (userPermissions.profile_verification.length === 0 ||
          (userPermissions.profile_verification.length > 0 &&
            !userPermissions.profile_verification.includes('View')))
      "
      >Permission Denied.</template
    >
  </div>
</template>
<script>
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";
import SideModal from "../components/SideModal.vue";
import AddEditVerification from "../components/AddEditVerification.vue";
import ViewVerificationStatus from "../components/ViewVerificationStatus.vue";
import verificationDashboardService from "../services/verificationDashboard.service";
import common from "../mixins/common";
import ImportVerification from "../components/ImportVerification.vue";
import ViewVerification from "../components/ViewVerification.vue";

export default {
  data() {
    return {
      loading: true,
      uploadPath: process.env.VUE_APP_UPLOAD_BASE_PATH,
      sideModals: {
        addEdit: { show: false },
        newRecord: { show: false },
        viewStats: { show: false },
        import: { show: false },
        view: { show: false },
      },
      viewStats: {
        status: false,
        BDTeamProfileName: "",
        data: {},
      },
      verification: {},
      recentVerifications: [],
      verificationStatus: [],
    };
  },
  mixins: [common],
  components: {
    SideModal,
    AddEditVerification,
    ViewVerificationStatus,
    ImportVerification,
    ViewVerification,
  },
  async mounted() {
    await this.getData();
    this.loading = false;
  },
  methods: {
    closeSideModal() {
      this.verification = {};
      document.body.classList.remove("lmodal-open");
      this.sideModals.newRecord.show = false;
      this.sideModals.addEdit.show = false;
      this.sideModals.viewStats.show = false;
      this.sideModals.import.show = false;
      this.sideModals.view.show = false;
    },
    async openSideModal(action, Id = "") {
      let vm = this;

      await vm.$store.dispatch("getUserDetails");

      // vm.closeSideModal();
      if (
        vm.userPermissions &&
        vm.userPermissions.profile_verification &&
        vm.userPermissions.profile_verification.length > 0 &&
        vm.userPermissions.profile_verification.includes("View")
      ) {
        if (
          action === "newRecord" &&
          vm.userPermissions.profile_verification.includes("Add")
        ) {
          vm.sideModals.addEdit.show = false;
          vm.sideModals.viewStats.show = false;
          vm.sideModals.import.show = false;
          vm.sideModals.newRecord.show = true;
          setTimeout(() => {
            document.body.classList.add("lmodal-open");
          }, 300);
          return;
        } else if (
          action === "createVerification" &&
          vm.userPermissions.profile_verification.includes("Add")
        ) {
          vm.sideModals.newRecord.show = true;
          vm.sideModals.viewStats.show = false;
          vm.sideModals.import.show = false;
          vm.sideModals.addEdit.show = true;
          setTimeout(() => {
            document.body.classList.add("lmodal-open");
          }, 300);
        } else if (
          action === "editVerification" &&
          vm.userPermissions.profile_verification.includes("Update")
        ) {
          try {
            vm.$store.commit("loadingStatus", true);
            const response = await verificationDashboardService.edit(Id);
            const data = response.verifiedProfileData;
            vm.verification = {
              fields: {
                Id: data.Id,
                uprosterProfileId: data.UprosterProfileId,
                responsiblePersonId: data.ResponsiblePersonId,
                documents: [],
                uploadedDocuments: data.documents,
                status: data.Status,
                reason: data.FailureComment,
                uproster_profile: {
                  ProfileName: data.uproster_profile.ProfileName,
                  deleted_at: data.uproster_profile.deleted_at,
                },
                b_d_team_profile: {
                  Name: data.b_d_team_profile.Name,
                  deleted_at: data.b_d_team_profile.deleted_at,
                },
              },
            };

            vm.sideModals.addEdit.show = true;
            setTimeout(() => {
              document.body.classList.add("lmodal-open");
            }, 300);
            vm.$store.commit("loadingStatus", false);
          } catch (error) {
            const message = vm.errorMessage(error);
            vm.toastMessage(message, "error");
            vm.$store.commit("loadingStatus", false);
          }
        } else if (
          action === "viewVerification" &&
          vm.userPermissions.profile_verification.includes("View")
        ) {
          try {
            vm.$store.commit("loadingStatus", true);
            const response = await verificationDashboardService.edit(Id);
            const data = response.verifiedProfileData;
            vm.verification = {
              fields: {
                Id: data.Id,
                uprosterProfileId: data.UprosterProfileId,
                responsiblePersonId: data.ResponsiblePersonId,
                documents: [],
                uploadedDocuments: data.documents,
                status: data.Status,
                reason: data.FailureComment,
                uproster_profile: {
                  ProfileName: data.uproster_profile.ProfileName,
                  deleted_at: data.uproster_profile.deleted_at,
                },
                b_d_team_profile: {
                  Name: data.b_d_team_profile.Name,
                  deleted_at: data.b_d_team_profile.deleted_at,
                },
              },
            };

            vm.sideModals.view.show = true;
            setTimeout(() => {
              document.body.classList.add("lmodal-open");
            }, 300);
            vm.$store.commit("loadingStatus", false);
          } catch (error) {
            const message = vm.errorMessage(error);
            vm.toastMessage(message, "error");
            vm.$store.commit("loadingStatus", false);
          }
        } else if (
          action === "viewStats" &&
          vm.userPermissions.profile_verification.includes("View")
        ) {
          try {
            vm.$store.commit("loadingStatus", true);
            const response = await verificationDashboardService.getStats(Id);
            const data = response;
            vm.viewStats.BDTeamProfileName = data.BDTeamProfileDetail.Name;
            vm.viewStats.data = data;

            vm.sideModals.viewStats.show = true;
            setTimeout(() => {
              document.body.classList.add("lmodal-open");
            }, 300);
            vm.$store.commit("loadingStatus", false);
          } catch (error) {
            const message = vm.errorMessage(error);
            vm.toastMessage(message, "error");
            vm.$store.commit("loadingStatus", false);
          }
        } else if (
          action === "importVerification" &&
          vm.userPermissions.profile_verification.includes("Add")
        ) {
          vm.sideModals.newRecord.show = false;
          vm.sideModals.viewStats.show = false;
          vm.sideModals.addEdit.show = false;
          vm.sideModals.import.show = true;
          setTimeout(() => {
            document.body.classList.add("lmodal-open");
          }, 300);
        }
      }
    },
    async getData() {
      let vm = this;
      const userPermissions = await vm.userPermissions;
      if (
        userPermissions &&
        userPermissions.profile_verification &&
        userPermissions.profile_verification.length > 0
      ) {
        try {
          vm.$store.commit("loadingStatus", true);
          const response = await verificationDashboardService.get();
          vm.recentVerifications = response.recentVerifications;
          vm.verificationStatus = response.businessTeamStats;

          var dT = $("#recent-verification").DataTable();
          dT.destroy();

          var dTT = $("#verification-status").DataTable();
          dTT.destroy();

          vm.$nextTick(function () {
            var d = new Date(),
              month = "" + (d.getMonth() + 1),
              day = "" + d.getDate(),
              year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            let tableOptions = {
              dom: "Bfrtip",
              buttons: [
                {
                  extend: "csv",
                  filename:
                    "Recent Verifications " + [year, month, day].join("-"),
                  extension: ".csv",
                  exportOptions: {
                    columns: "th:not(:last-child)",
                  },
                },
              ],
              iDisplayLength: 5,
              bLengthChange: false,
              ordering: false,
              info: false,
              /* Disable initial sort */
              aaSorting: [],
              fnDrawCallback: function (oSettings) {
                if (oSettings._iDisplayLength >= oSettings.fnRecordsDisplay()) {
                  $(oSettings.nTableWrapper)
                    .find(".dataTables_paginate")
                    .hide();
                } else {
                  $(oSettings.nTableWrapper)
                    .find(".dataTables_paginate")
                    .show();
                }
              },
            };

            $("#recent-verification").DataTable(tableOptions);
            tableOptions.buttons[0].filename =
              "Business Team Stats " + [year, month, day].join("-");
            tableOptions.iDisplayLength = 50;
            $("#verification-status").DataTable(tableOptions);

            $(".dt-buttons, .dataTables_filter").hide();
          });
          vm.$store.commit("loadingStatus", false);
        } catch (error) {
          const message = vm.errorMessage(error);
          vm.toastMessage(message, "error");
          vm.$store.commit("loadingStatus", false);
        }
      }
    },
    async deleteRecentVerification(Id) {
      let vm = this;

      await vm
        .$swal({
          title: "Delete Verification",
          text: "Are you sure you want to delete this Verification?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#43832a",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async function (result) {
          if (result.isConfirmed) {
            try {
              vm.$store.commit("loadingStatus", true);
              await verificationDashboardService.destroy(Id);
              vm.getData();
              vm.$store.commit("loadingStatus", false);
            } catch (error) {
              const message = vm.errorMessage(error);
              vm.toastMessage(message, "error");
              vm.$store.commit("loadingStatus", false);
            }
          }
        });
    },
    downloadRecentVerificationCsv: async function () {
      $("#recent-verification_wrapper .buttons-csv").click();
    },
    downloadVerificationStatusCsv: async function () {
      $("#verification-status_wrapper .buttons-csv").click();
    },
  },
  watch: {
    userPermissions: {
      handler() {
        this.getData();
      },
    },
  },
};
</script>
